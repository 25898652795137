var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Without directive"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeWithoutDirective) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("You can copy text without a specific button. Use ")]), _c('code', [_vm._v("$copyText")]), _c('span', [_vm._v(" to copy text when event got fired.")])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-form-group', {
    staticClass: "mb-0 mr-1"
  }, [_c('b-form-input', {
    model: {
      value: _vm.message1,
      callback: function callback($$v) {
        _vm.message1 = $$v;
      },
      expression: "message1"
    }
  })], 1), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.doCopy
    }
  }, [_vm._v(" Copy! ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }