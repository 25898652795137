var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Using Directive"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDirective) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v("Use directive on button to copy text")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-form-group', {
    staticClass: "mb-0 mr-1"
  }, [_c('b-form-input', {
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  })], 1), _c('b-button', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.message,
      expression: "message",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.onError,
      expression: "onError",
      arg: "error"
    }, {
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Copy! ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }